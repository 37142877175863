footer {
    background: $black;
    padding: 75px 0;
    color: $white;
    line-height: 1.2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media screen and (min-width: 768px){
        flex-direction: row;
    }
    .footer-box {
        padding: 0 55px;
        font-weight: 300;
        line-height: 1.25;
        margin: 5px;
        width: 100%;
        @media screen and (min-width: 768px){
            margin: 0;
            width: auto;
            padding: 0 25px;
        }
        img {
            max-width: 100%;
        }
        a {
            color: inherit;
            &:hover {
                text-decoration: underline;
            }
        }
    }

}