@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;900&display=swap');
$black: #212121;
$darkgrey: #474747;
$lightgrey: #F5F5F5;
$white: #fff;
$bodybg: $white;
$black2: #212121;
$linebg: #212121;
$darknavy: #424A55;
$yellow: #FBB600;
$orange: #FF7600;
$pink: #FF4155;
$fontcolor: $black;
$transblack: rgba($black, 0.8);
$transwhite: rgba($white, 0.8);
$langcolor: $darkgrey;
$bodyfont: 'Montserrat', sans-serif;
$fontsize_desktop: 16px;
$fontsize_laptops: 16px;
