.rounded {
     border-radius: 32px 32px 0px 0px;
}
.greybg {
    background: $lightgrey;
}
.darkgrey {
    color: $darkgrey;
}

.greybgdesktop {
    background: none;    
    @media screen and (min-width: 768px){
        background: $lightgrey;
    }
}

.yellowbg {
    background: $yellow
}
.pulled {
    position: relative;
    top: -25px;
}
.table-wrapper {
    width: 100%;
    overflow: auto;
    height: 250px;
    margin-bottom: 15px;
    @media screen and (min-width: 992px){
       height: auto;
       margin-bottom: 0;
    }
    @media screen and (min-width: 1200px){
        overflow: visible;
    }
}
.os-theme-dark.os-host-transition > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
    background: $black2;
}

.content {
    padding: 0 7.3%;
    position: relative;
}

.section-title {
    padding: 72px 0 42px 0;
    text-align: left;
    font-size: 1.5rem;
    line-height: 1.2;
    font-weight: 600;
    @media screen and (min-width: 768px){
        font-size: 2rem;
    }
    @media screen and (min-width: 1200px){
        font-size: 2.875rem;
    }
    @media screen and (min-width: 1700px){
        font-size: 3.875rem;
    }
    &.down {
        @media screen and (min-width: 992px){
            position: absolute;
            top: 200px;
            width: 100%;
            left: 0;
        }
    }
    &.small {
        text-align: left;
        font-size: 1.5rem;
        font-weight: 600;
        @media screen and (min-width: 768px){
            font-size: 1rem;
        }
    }
    &.left {
        text-align: left;
    }
    &.with-padding {
        padding-left: 7.3%;
        padding-right: 7.3%;
        @media screen and (min-width: 768px){
            padding-left: 0;
            padding-right: 0;
        }
    }
}
.places {
    padding-bottom: 72px;
    @media screen and (min-width: 992px){
        padding-bottom: 113px;
    }
    .places-boxes {
        position: relative;
        @media screen and (min-width: 768px){
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 0 7.3%;
        }
        @media screen and (min-width: 992px){
            padding-top: 120px;
        }
        .slick-list {
            padding: 80px 20% 0 0;
            @media screen and (min-width: 525px){
                padding: 105px 20% 0 0;
            }

        }
        .slick-track {
            display: flex !important;
        }
        .next, .prev {
            position: absolute;
            width: 30px;
            height: 8px;
            border: 1px solid $darkgrey;
            border-top:0;
            top: -30px;
            cursor: pointer;
        }
        .next {
            right: 25px;
            border-left: 0;
            transform: skew(50deg);
        }
        .prev {
            right: 100px;
            border-right: 0;
            transform: skew(-50deg);
        }
        .places-box {
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 14px;
            width: 100%;
            background: $white;
            padding: 150px 25px 30px 25px;
            color: $black2;
            margin: 10px;
            position: relative;
            height: auto;
            @media screen and (min-width: 768px){
                width: 49%;
                margin: 140px 0 0 0;
            }
            @media screen and (min-width: 992px){
                width: 24.5%;
                margin: 0;
            }
            .place-img-wrapper {
                position: absolute;
                width: 85%;
                top: 0;
                left: 50%;
                transform: translate(-50%, -55%);
                text-align: center;
                .place-img {
                    max-width: 100%;
                }

            }
           
            .place-title {
                padding-bottom: 14px;
                border-bottom: 1px solid $black;
                font-weight: 300;
                font-size: 1.3rem;
                @media screen and (min-width: 768px){
                    font-size: 1.5rem;
                }
                @media screen and (min-width: 992px){
                    font-size: 1.7rem;
                }
                @media screen and (min-width: 1200px){
                    font-size: 2rem;
                    width: 80%;
                }
                @media screen and (min-width: 1700px){
                    font-size: 2.25rem;
                    width: 100%;
                }
            }
            .place-visit {
                color: rgba($black, 0.5);
                text-transform: uppercase;
                font-weight: 300;
                font-size: 0.75rem;
                margin-top: 15px;
            }
            .places-list {
                margin-top: 30px;
                @media screen and (min-width: 768px){
                    margin-top: 50px;
                }
                li {
                    padding: 10px 0;
                    line-height: 1.2;
                    @media screen and (min-width: 768px){
                        padding: 20px 0;
                    }
                    a {
                        color: inherit;
                        display: inline-block;
                        position: relative;
                        padding-right: 40px;
                        &::before {
                            content: '';
                            width: 20px;
                            height: 20px;
                            display: block;
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            border-radius: 50%;
                            border: 1px solid $black2;
                            @include shift(border);
                        }
                        &::after {
                            content: '';
                            width: 0;
                            height: 0;
                            border-top: 4px solid transparent;
                            border-left: 8px solid $black2;
                            border-bottom: 4px solid transparent;
                            position: absolute;
                            right: 4px;
                            top: 50%;
                            transform: translateY(-50%);
                            @include shift(border);
                        }
                        &:hover {
                            &::before {
                                border-color: $pink;
                            }
                            &::after {
                                border-left: 8px solid $pink;
                            }
                        }
                    }
                }
            }
        }
    }
}

.philosophy {
    display: flex;
    padding: 0px 0px 40px 0px;
    @media screen and (min-width: 1200px) {
        padding: 130px 0px;
    }
    .left {
        width: 85%;
        margin: auto;
        @media screen and (min-width: 1200px){
            width: 50%;
            padding-left: 7.3%;
            padding-right: 60px;
        }
        .text {
            p {
                line-height: 1.3;
                margin-bottom: 20px;
            }
        }
        .icons-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-top: 30px;
            li {
                width: 48%;
                padding: 25px 0;
                line-height: 1.3;
                @media screen and (min-width: 768px){
                    width: 32%;
                }
                @media screen and (min-width: 992px){
                    width: 24%;
                }
                @media screen and (min-width: 1200px){
                    width: 32%;
                }
                .icon {
                    height: 50px;
                    display: block;
                    margin-bottom: 10px;
                }
            }
        }
    }
    .right {
        display: none;
        @media screen and (min-width: 1200px){
            width: 50%;
            display: block;
        }
        .right-image {
            object-fit: cover;
            width: 100%; 
            height: 100%;
        }

    }
}

.why {
    margin-top: -60px;
    padding-top: 60px;
    @media screen and (min-width: 768px){
        margin-top: 0;
        padding-top: 0;
    }
}
.why-columns {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media screen and (min-width: 768px){
        flex-direction: row;
        padding: 0 5%;
    }
    .why-column {
        width: 100%;
        padding-bottom: 0;
        @media screen and (min-width: 768px){
            width: 47%;
            padding-bottom: 50px;
        }
        p {
            padding-bottom: 40px;
            font-size: 1rem;
            line-height: 1.33;
        }
    }
}
.choosing {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media screen and (min-width: 768px){
        flex-direction: row;
        flex-wrap: wrap;
    }
    .choosing-box {
        border: 1px solid gold;
        width: 100%;
        padding: 30px 16px;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #70707063;
        border-radius: 6px;
        margin-bottom: 12px;
        @media screen and (min-width: 768px){
            width: 49%;
            padding: 40px 16px;
            margin-bottom: 16px;
           
        }
        @media screen and (min-width: 992px){
            width: 24%;
            margin-bottom: 0;
        }
        .choosing-img {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 220px;
            @media screen and (min-width: 1200px){
                height: 320px;
            }
            img {
                max-height: 100%;
                @media screen and (min-width: 768px){
                    width: 100%;
                    height: auto;
                    max-width: 365px;
                }
            }
        }
        .choosing-text {
            font-size: 1rem;
            padding: 25px 16px;
            line-height: 1.5;
            @media screen and (min-width: 768px){
                line-height: 1.33;
            }
            @media screen and (min-width: 992px){
                font-size: 0.85rem;
                padding: 25px 0px;
            }
            @media screen and (min-width: 1200px){
                padding: 25px 16px;
                line-height: 1;
                font-size: inherit;

            }
            @media screen and (min-width: 1600px){
                padding: 55px 16px;

            }
        }
    }
}

.video {
    .content {
        padding: 0;
        @media screen and (min-width: 768px){
            padding: 0 7.3%;
        }
    }
    
    .video-wrapper {
        display: flex;
        justify-content: center;
        video {
            width: 100%;
            height: auto;
           
            @media screen and (min-width: 1200px){
                width: 1088px;
            }
        }
    }
}

.career {
    //background: $yellow;
    background-image: url('../images/kariera-bg-mob2.png'), linear-gradient($yellow 83%, white 83%);
    background-size: auto 620px, cover;
    background-repeat: no-repeat;
    background-position: center bottom, top left;
    @media screen and (min-width: 992px){
        background: url('../images/kariera-bg.png') center top no-repeat;
        background-size: auto 100%;
        position: relative;
        top: 25px;
        z-index: -1;
    }
    .career-text {
       padding-bottom: 550px;
        @media screen and (min-width: 992px){
            height: 1258px;
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 675px;
            padding-top: 200px;
            padding-bottom: 0;
        }
        p {
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.33;
            padding: 30px 0px;
            @media screen and (min-width: 992px) and (max-width: 1200px){
                font-size: 1.25rem;
            }
        }
        

    }
}

.structure {
    .slick-list {
        padding: 0 20% 0 0;
    }
    .next, .prev {
        position: absolute;
        width: 30px;
        height: 8px;
        border: 1px solid $darkgrey;
        border-top:0;
        top: -30px;
        cursor: pointer;
    }
    .next {
        right: 25px;
        border-left: 0;
        transform: skew(50deg);
    }
    .prev {
        right: 100px;
        border-right: 0;
        transform: skew(-50deg);
    }

    .structure-1 {
        position: relative;
    
        @media screen and (min-width: 768px){
            display: flex;
        }
        .structure-box {
            width: 260px;
            background: $darkgrey;
            color: $white;
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #70707063;
            border-radius: 6px;
            padding: 8px 8px 16px 8px;
            min-height: 122px;
            margin-right: 10px;
            @media screen and (min-width: 768px){
                margin-right: 16px;
            }
            .box-small {
                font-size: 0.583rem; 
                padding-bottom: 15px;
            }
            .box-big {
                font-size: 1rem;
                font-weight: 600;
                text-align: center;
                padding: 3px 0;
            }
            .box-medium {
                font-size: 1rem;
                font-weight: 300;
                text-align: center;
                padding: 3px 0;
                &.smaller {
                    font-size: 0.625rem;
                }
            }
        }
    }
    .structure-2 {
        position: relative;
        @media screen and (min-width: 768px){
            display: flex;
        }
        .structure-box {
            width: 400px;
            background: $white;
            color: $darkgrey;
            box-shadow: 0px 3px 6px #00000029;
            border: 0.30000001192092896px solid #70707063;
            border-radius: 6px;
            padding: 20px 10px;
            margin-right: 10px;
            min-height: 122px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @media screen and (min-width: 768px){
                margin-right: 16px;
            }
            .box-big {
                font-size: 1rem;
                font-weight: 600;
                padding: 5px 0;
                text-align: center;
            }
            .box-medium {
                font-size: 1rem;
                font-weight: 300;
                text-align: center;
            }
        }
    }

    .structure-3 {
        background-image: linear-gradient(transparent, white); 
        margin-top: 70px;
    }
    
    .structure-3-inner {
        background: $darkgrey;
        color: $white;
        border-radius: 0px 50px;
        padding: 50px 0;
        font-size: 1rem;
        @media screen and (min-width: 768px){
            padding: 90px 0;
        }
        .columns {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            @media screen and (min-width: 768px){
                flex-direction: row;
            }
            .left {
                width: 100%;
                line-height: 1.33;
                padding-right: 0;
                padding-bottom: 30px;
                @media screen and (min-width: 768px){
                    width: 45%;
                    padding-right: 60px;
                    padding-bottom: 0;
                }
            }
            .right {
                width: 100%;
                @media screen and (min-width: 768px){
                    width: 55%;
                }
                .info {
                    background: $white;
                    padding: 20px 40px;
                    color: $darkgrey;
                    box-shadow: 0px 3px 6px #00000029;
                    border: 0.30000001192092896px solid #70707063;
                    border-radius: 6px;
                    font-size: 1.5rem;
                    @media screen and (min-width: 768px){
                        font-size: 1rem;
                        padding: 25px 70px;
                    }
                    &:first-child {
                        margin: 0 0 30px 0;
                    }
                }
            }
        }
        .summary {
            font-weight: 600;
            padding-top: 35px;
            font-size: 1.5rem;
            @media screen and (min-width: 992px){
                font-size: 1rem;
                padding-top: 80px;
            }
            @media screen and (min-width: 1200px){
                padding-top: 130px;
            }
        }
    }
}



.model {
    font-size: 1rem;
    line-height: 1.33;
    .image-right {
        float: right;
        margin: 0 0 50px 50px ;
        width: 40%;
        max-width: 100%;
        display: none;
        @media screen and (min-width: 768px){
            display: block;
        }
    }
    p {
        margin-bottom: 30px;
    }
    ol {
        counter-reset: modelitem;
        padding: 20px 0 0 0;
        li {
            padding-bottom: 40px;
            counter-increment: modelitem;
            position: relative;
            @media screen and (min-width: 768px){
                padding-left: 90px;
            }
            &:before {
                display: block;
                font-weight: 600;
                content: counter(modelitem) ".";
                position: static;
                left: 0;
                @media screen and (min-width: 768px){
                    position: absolute;
                    display: inline-block;
                }
            }
            &:last-child {
                padding-bottom: 0;
            }
        }
    }
}

.details {
    display: flex;
    margin-bottom: 3rem;
    .content {
        padding: 0;
        @media screen and (min-width: 768px){
            padding: 0 7.3%;
        }
    }
    .small {
        padding-top: 0;
        @media screen and (min-width: 1200px){
            padding-top: 72px;  
        }
    }
    ul.tabs {
        display: none;
        @media screen and (min-width: 992px){
            display: flex;
            flex-direction: column;
            padding-left: 7.3%;
        }
        li {
            background: $white;
            color: $black2;
            margin: 5px 0;
            display: block;
            padding: 15px 30px;
            cursor: pointer;
            display:flex;
            align-items: center;
            max-width: 320px;
            min-height: 65px;
            font-size: 1.125rem;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 4px;
            @include shift(background);
            @include shift(color);
            &.current {
                background: $lightgrey;
            }
            &:hover {
                background: $lightgrey; 
            }
        }
    }
    .right-tabs {
        margin: auto;
        width: 85%;

        @media screen and (min-width: 992px){
            padding-left: 30px;
            flex-grow: 1;
        }
        @media screen and (min-width: 1200px){
            padding-left: 80px;
        }
    }

    .tab-content{
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 4px;
        margin: 5px 0;
        overflow: hidden;

        @media screen and (min-width: 992px){
            display: none;
            box-shadow: none;
            border-radius: 0;
            margin: 0;
        }
        .tab-image {
            display: none;
            @media screen and (min-width: 992px){
                display: block;
                height: 475px;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .tab-subtitle {
            font-size: 1.5rem;
            padding: 30px 0 25px;
            @media screen and (min-width: 768px){
                padding: 60px 0 50px;
            }
            &.places-subtitle{
                padding: 30px 0 60px;
                @media screen and (min-width: 768px){
                    padding: 60px 0 50px;
                }
            }
        }
        .features {
            display: flex;
            margin-bottom: 30px;
            flex-wrap: wrap;
            @media screen and (min-width: 768px){
                margin-bottom: 60px;
            }
            @media screen and (min-width: 1200px){
                flex-wrap: nowrap;
            }
            &.vizja-features {
                flex-wrap: wrap;
                align-items: flex-start;
                @media screen and (min-width: 1300px){
                    flex-wrap: nowrap;
                }  
            }
            .feature {
                display: flex;
                align-items: center;
                margin-right: 20px;
                img {
                    flex-shrink: 0;
                }
                span {
                    display:block;
                    padding: 15px;
                    line-height: 1.3;
                }
                &.vizja-feature {
                    flex-direction: column;
                    justify-content: center;
                    flex-grow: 1;
                    flex-basis: 0;
                    max-width: 100px;
                    span {
                        text-align: center;
                        font-size: 10px;
                    }
                }
            }
        }
        .tab-text {
            p {
                line-height: 1.3;
                @media screen and (min-width: 992px){
                    width: 80%;
                }
                a {
                    color: $darknavy;
                }
            }
        }
        .institutions {
            display: flex;
            align-items: flex-start;
            margin-bottom: 50px;
            margin-left: -10px;
            margin-right: -10px;
            @media screen and (min-width: 768px){
                margin-left: 0;
                margin-right: 0;
            }
            .goto {
                width: 90px;
                margin-right: 100px;
                position: relative;
                display: none;
                line-height: 1.2;
                .arrow {
                    display: block;
                    width: 120%;
                    height: 1px;
                    background: $black2;
                    position: absolute;
                    left: 0;
                    bottom: -30px;
                    &::after {
                        content: '';
                        width: 0;
                        height: 0;
                        border-top: 4px solid transparent;
                        border-left: 8px solid $black2;
                        border-bottom: 4px solid transparent;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);

                    }
                }
                @media screen and (min-width: 768px){
                    display: block;
                }
            }
            .institutions-items {
                position: relative;
                width: 100%;
                .next, .prev {
                    position: absolute;
                    width: 30px;
                    height: 8px;
                    border: 1px solid $darkgrey;
                    border-top:0;
                    top: -30px;
                    cursor: pointer;
                }
                .next {
                    right: 25px;
                    border-left: 0;
                    transform: skew(50deg);
                }
                .prev {
                    right: 100px;
                    border-right: 0;
                    transform: skew(-50deg);
                }

                .slick-list {
                    padding: 0 20% 0 0;
                    @media screen and (min-width: 576px){
                        padding: 0 30% 0 0;
                    }

                }
                 
                .institution {
                    margin: 0 20px;
                    padding: 10px 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    &:hover {
                        .name {
                            color: $white;
                            background: $black2
                        }
                    }
                    img {
                        margin: auto;
                        max-width: 100%;
                        height: 180px;
                        @media screen and (min-width: 768px){
                            height: auto;
                        }

                    }
                    .name {
                        box-shadow: 0px 3px 6px #00000029;
                        border-radius: 4px;
                        text-align: center;
                        padding: 10px 0;
                        margin: 20px 0 0 0;
                        display: block;
                        color: $black2;
                        @include shift(background);
                        @include shift(color);
                        @media screen and (min-width: 768px){
                            min-width: unset;
                            width: 100%;
                        }
                    }
                    @media screen and (min-width: 768px){
                        width: 40%;
                    }
                    @media screen and (min-width: 1200px){
                        width: auto;
                        max-width: 220px;
                    }
                }
                @media screen and (min-width: 768px){
                    display: flex;
                    flex-wrap: wrap;
                    width: 80%;
                    padding: 0;
                }

            }
        }
        .accordion-title {
            color: $black2;
            padding: 15px 20px;
            cursor: pointer;
            font-size: 1.125rem;
            position: relative;
            &:after {
                content: '';
                width: 10px;
                height: 10px;
                display: block;
                border-top: 2px solid $black;
                border-right: 2px solid $black;
                position: absolute;
                top: 50%;
                right: 20px;  
                transform: translateY(-40%) rotate(-45deg);
                transition: transform 0.5s;
            }
            &.current {
                background: $lightgrey;
                &:after {
                    transform: translateY(-40%) rotate(135deg);
                }
            }
            @media screen and (min-width: 992px){
                display: none;
            } 
        }
        .table-wrapper {
            display: none;
            padding: 0 10px;
            @media screen and (min-width: 992px){
                display: block;
                padding: 0;
            }
        }
        &:first-child {
            display: block;
            .table-wrapper {
                display: block;
            }
        }
        
        .subjects {
            width: 1300px;
            border-collapse: collapse;
            border-radius: 6px;
            overflow: hidden;
            box-shadow: 0px 3px 6px #00000029;
            @media screen and (min-width: 768px){
                width: 100%;
            }
            tr:nth-child(odd) {
                background: $lightgrey;
            }
            tr:not(:first-child) {
                td:not(:first-child) {
                    text-align: center;
                }
            }
            tr:first-child {
                td {
                    font-weight: 600;
                    line-height: 1.33;
                }
            }
    
            td {
                border-right: 1px solid darken($lightgrey, 10%);
                padding: 20px 28px;
                font-size: 1.5rem;
                vertical-align: middle;
                @media screen and (min-width: 768px){
                    font-size: 1rem;
                }
                &:last-child {
                    border: none;
                }

            }
        }
    }

}

.scholarship {
    .scholarship-columns {
        display: flex;
        justify-content: space-between;
        padding-bottom: 70px;
        flex-direction: column;
        @media screen and (min-width: 992px){
            flex-direction: row;
        }
        .column {
            width: 100%;
            font-size: 1rem;
            @media screen and (min-width: 992px){
                width: 32%;
            }
            .column-title {
                font-weight: bold;
                padding: 20px 0 30px 0;
                text-transform: uppercase;
                font-size: 1.5rem;
                @media screen and (min-width: 768px){
                    font-size: 1rem;
                }
            }
            .column-text {
                line-height: 1.33;
                font-size: 1.5rem;
                @media screen and (min-width: 768px){
                    font-size: 1rem;
                }
            }

        }
    }
}

.promotion {
    font-size: 1rem;
    margin-bottom: 28px;
    @media screen and (min-width: 768px){
        min-height: 600px;
        position: relative;
    }
    .image-container {
        display: none;
        @media screen and (min-width: 768px){
            width: 52%; 
            height: 100%;
            background-size: auto 100%; 
            background-position: center center;
            position: absolute;
            right: 0;
            display: block;
        }
    }
    .promotion-title {
        font-size: 2.25rem;
        line-height: 1.2;
        color: $white;
        font-weight: 900;
        @media screen and (min-width: 768px){
            font-size: 4.25rem;
        }
    }
    .promotion-text {
        line-height: 1.33;
        padding: 30px 0;
        font-size: 1.5rem;
        @media screen and (min-width: 768px){
            font-size: 1rem;
        }
    }
    .promotion-link {
        margin-bottom: 117px;
        display: inline-block;
        text-transform: uppercase;
        padding: 10px 30px;
        color: $white;
        background: $darkgrey;
        box-shadow: 0px 3px 6px #00000029;
        font-size: 23px;
        &:hover {
            background: $white;
            color: $darkgrey;
        }
    }
}
.promotion-table {
    padding-bottom: 85px;
    .content {
        padding-right: 0;
        @media screen and (min-width: 768px){
            padding-right: 7.3%;
        }
    }
    table {
        width: 100%; 
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 6px;
        overflow: hidden;
        td {
            padding: 20px;
            border-right: 1px solid $white;
            border-bottom: 1px solid $white;
            background: $lightgrey;
            font-size: 16px;
            &:last-child {
                border-right: 0;
            }
            &.yellowbg {
                background: $yellow;
                font-weight: 600;
            }
            &.no-right-border {
                border-right: 0;
                font-weight: 600;
            }
            &.padded {
                padding-top: 50px;
                padding-bottom: 50px;
            }
            div {
                display: flex;
                justify-content: center;
            }
        }

        tr {
            &:first-child {
                td {
                    background: $darkgrey;
                    color: $white;
                    font-weight: 600;
                    vertical-align: top;
                    padding: 8px 0;
                    border-bottom: 0;
                    font-size: 1.5rem;
                    @media screen and (min-width: 768px){
                        font-size: 1rem;
                    }
                    div {
                        display: flex;
                        justify-content: center;
                        height: 55px;
                        align-items: center;
                    }
                }
            }
            &:last-child {
                td {
                    border-bottom: 0;
                }
            }
        }
    }
}