:root {
    @media screen and (min-width: 1200px){
        font-size: $fontsize_laptops;
    }
    @media screen and (min-width: 1600px){
        font-size: $fontsize_desktop;
    }
    }
body{
    font-weight: 400;
    font-family: $bodyfont;
    color: $fontcolor;
    background: $bodybg;
    &.mobile-menu-on {
        overflow: hidden;
        @media screen and (min-width: 992px){
            overflow: visible;
        }
    }
}
a {
    @include shift;
    text-decoration: none;
}
strong {
    font-weight: 600;
}
