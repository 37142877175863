header {
    position: relative;

    .logo {
        //padding-top: 8px;
        //position: absolute;
        width: 100%;
        height: 15.4vh;
        display: flex;
        align-items: center;
        max-height: 120px;
        @media screen and (orientation: landscape) {
            height: 25vh;
        }
        @media screen and (min-width: 768px){
            max-height: 100px;
        }
        @media screen and (min-width: 992px){
           // padding-top: 40px;
           height: 15.4vh;
        }
        @media screen and (min-width: 1024px){
            max-height: none;
        }
        img {
            width: 180px;
            @media screen and (min-width: 992px){
                width: 234px;
            }
        }
    }

    .mobile-title {
        padding-bottom: 30px;
        @media screen and (min-width: 992px){
            display: none;
        }

        h1 {
            padding-left: 7.3%;
            font-weight: 600;
            font-size: 2rem;
        }
    }

    nav {
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 0;
        z-index: 2;
        .lang {
            height: 50px;
            width: 100px;
            background: $langcolor;
            padding: 20px 20px 30px 20px;
            border-radius: 0px 0px 0px 23px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: -100px;
            @media screen and (min-width: 768px){
                height: 80px;
                width: 150px;
                left: -150px;
            }
            a {
                color: $white;
                display: inline-block;
                padding: 5px;
                text-transform: uppercase;
                font-size: 0.583rem;
                &.active {
                    font-weight: 600;
                }
            }
            .showmenu {
                position: absolute;
                bottom: 15px;
                right: 15px;
                display: block;
                width: 0;
                height: 0;
                border-top: 5px solid transparent;
                border-right: 10px solid $white;
                border-left: 0;
                border-bottom: 5px solid transparent;
                cursor: pointer;
                @media screen and (min-width: 768px){
                    border-top: 8px solid transparent;
                    border-right: 16px solid $white;
                    border-left: 0;
                    border-bottom: 8px solid transparent;
                }
                &.back {
                    border-right: 0;
                    border-left: 10px solid $white; 
                    @media screen and (min-width: 768px){
                        border-left: 16px solid $white; 
                    }
                }
            }
        }
        .menu {
            background: $white;
            box-shadow: 0px 3px 6px #00000029;
            position: absolute;
            top: 0;
            padding-top: 100px;
            left: 0;
            height: 100%;
            width: 170px;
            z-index: -1;
            @include shift(left);
            @media screen and (min-width: 768px){
                width: 150px;
            }
            &.shown {
                left: -170px;
                @media screen and (min-width: 768px){
                    left: -150px;
                }
            }
            .menu-items {
                overflow: scroll;
                height: 90%;
                @media screen and (min-width: 992px){
                    overflow: visible;
                    height: auto;
                }

                li {
                    padding: 0px 10px;
                    &:last-child{
                        padding: 30px 0;
                        a {
                            background: $yellow;
                            padding: 30px 0;
                            text-align: center;
                            border: 0;
                            font-size: 13px;
                            text-transform: uppercase;
                            &:hover {
                                background: $darkgrey;
                                color: $yellow;
                            }
                        }
                    }
                }
                a {
                    padding: 30px 10px;
                    border-bottom: 1px solid $darkgrey;
                    font-weight: 500;
                    font-size: 20px;
                    display: block;
                    color: $darkgrey;
                   
                    @media screen and (min-width: 992px){
                        font-size: 18px;
                        padding: 20px 10px;
                    }
                    @media screen and (min-width: 992px) and (max-height: 700px){
                        font-size: 16px;
                        padding: 15px 10px;
                    }
                    @media screen and (min-width: 1600px){
                        font-size: 20px;
                        padding: 30px 10px;
                    }
                }
            }
            .os-theme-dark {
                & > .os-scrollbar {
                    & > .os-scrollbar-track {
                        //background-image: linear-gradient(to right, lighten($darkgrey, 5%), lighten($darkgrey, 5%) 2px, transparent 2px);
                        //background-position: 2px 0px;
                        & > .os-scrollbar-handle {
                            //background: rgba(lighten($darkgrey, 5%), 1);
                            background: rgba($darkgrey, 0.7);
                            width: 3px;
                        }
                    }
                }
            }
        }
        .reserve {
            position: absolute;
            left: -250px;
            bottom: 0;
            @media screen and (min-width: 768px){
                left: -400px;
            }
            a {
                display: inline-flex;
                align-items: center;
                padding: 15px;
                width: 250px;
                background: $orange;
                //background: transparent linear-gradient(96deg, #FF7600 0%, #FF7600 100%) 0% 0% no-repeat padding-box; 
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 8px 0px 0px 8px;
                text-transform: uppercase;
                color: $white;
                font-size: 0.667rem;
                &:hover {
                    background:darken($orange, 2%);
                }
                @media screen and (min-width: 768px){
                    width: 400px;
                }
            }
            img {
                padding: 0px 20px;
                border-right: 1px solid $white;
                margin-right: 20px;
                display: none;
                @media screen and (min-width: 768px){
                    display: block;
                }
            }
            .first {
                display: block;
                font-weight: 600;
                font-size: 1rem;
            }
        }
    }
    .top {
        height: auto;
        display: flex;
        flex-direction: column;
        @media screen and (min-width: 992px){
            height: 60vh;
           // min-height: 750px;
            flex-direction: row;
            max-height: 925px;
        }
        .left {
           // flex-grow: 1;
            padding: 0 7.3%;
            order: 2;
            @media screen and (min-width: 992px){
                order: 1;
                padding-right: 0;
                width: 50%;
            }
            @media screen and (min-width: 1200px){
                order: 1;
                padding-right: 0;
                width: 40%;
            }
            @media screen and (min-width: 1200px) and (min-height: 600px) and (max-height: 820px){
                width: 50%;
            }
            .title {
                display: none;
                @media screen and (min-width: 992px){
                    display: block;
                }
                @media screen and (min-width: 1200px){
                    padding-top: 40px;
                }
                @media screen and (min-width: 1600px){
                    padding-top: 110px;
                }
                h1 {
                    font-size: 2.875rem;
                    line-height: 1.2;
                    font-weight: 600;
                    letter-spacing: 0;
                    color: $black2;
                    @media screen and (min-width: 1700px){
                        font-size: 3.875rem;
                    }
                }
            }
            .top-text {
                padding-top: 40px;
                @media screen and (min-width: 992px){
                    padding-top: 30px;
                    padding-right: 30px;
                }
                @media screen and (min-width: 992px) and (max-height: 700px){
                    padding-top: 20px;
                    padding-right: 50px;
                }
                @media screen and (min-width: 1600px){
                    padding-top: 60px;
                }
                p {
                    margin-bottom: 20px;
                    line-height: 1.3;

                }
            }
            .top-boxes {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                width: 100%;
                padding-top: 20px;
                @media screen and (min-width: 992px){
                    padding-top: 80px;
                    width: 95%;
                    max-width: 535px;
                }
                .box {
                    padding: 10px 8px 10px 8px;
                    width: 90%;
                    box-shadow: 0px 3px 6px #00000029;
                    border: 1px solid #70707063;
                    border-radius: 6px;
                    margin: 8px auto;
                    position: relative;
                    background: $white;
                    @media screen and (min-width: 768px){
                        width: 48.8%;
                        margin: 8px 0;
                    }
                    .box-small {
                        font-size: 0.875rem;
                        @media screen and (min-width: 992px){
                            font-size: 0.583rem; 
                        }
                        
                    }
                    .box-big {
                        font-size: 1.25rem;
                        font-weight: 600;
                        padding: 20px 0;
                        height: 80px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        letter-spacing: 0;
                        font-size: 1.667rem;
                        @media screen and (min-width: 992px){
                            font-size: 1rem;
                        }
                    }
                    .box-link {
                        position: absolute;
                        right: 10px;
                        bottom: 10px;
                        a {
                            color: $yellow;
                            font-size: 12px;
                            position: relative;
                            display: inline-block;
                            padding-right: 30px;
                            &:after {
                                content:'';
                                display: block;
                                width: 0;
                                height: 0;
                                border-top: 8px solid transparent;
                                border-left: 16px solid $yellow;
                                border-bottom: 8px solid transparent;
                                position: absolute;
                                right: 0;
                                top: 50%;
                                transform: translateY(-50%);

                            }
                        }
                    }
                }
            }
        }
        .right {
            height: 250px;
            /*background-position: center top;
            background-repeat: no-repeat;
            background-size: cover;*/
            order: 1;
            //margin-top: 80px;
           /* @media screen and (min-width: 768px){
                background-position: center center;
            }*/
            @media screen and (min-width: 992px){
                width: 50%;
                height: 100%;
                order: 2;
                margin-top: 0;
            }
            @media screen and (min-width: 1200px){
                width: 60%;
            }
            @media screen and (min-width: 1200px) and (min-height: 600px) and (max-height: 820px){
                width: 50%;
            }

            .right-image {
                object-fit: cover;
                width: 100%; 
                height: 100%;
            }
        }   
    }
    .counters {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 40px;
        justify-content: space-between;
        @media screen and (min-width: 992px){
            height: 24.6vh;
            justify-content: center;
            align-items: center;
            flex-wrap: nowrap;
        }
        .counter-box {
            text-align: center;
            font-weight: 400;
            width: 30%;
            margin: 15px 0;

            @media screen and (min-width: 992px){
                width: auto;
                margin: 0px 80px;
            }
            .counter {
                font-size: 2.375rem;
                font-weight: 300;
                line-height: 1.5;
                @media screen and (min-width: 992px){
                    font-size: 3rem;
                }
            }
        }
    }
}